<template>
    <div class="quotation-container">
        <div style="text-align: right">
            <el-button plain class="downQuote" @click="() => { handleDown(custInfo.name + '-报价单') }">导出PDF</el-button>
            <el-button plain class="downQuoteGrey" @click="() => { $router.push(`/quoted/complete?id=${$route.query.id}`) }">返回</el-button>
        </div>
        <div id="pdf">
            <div class="pdf-title">客服服务报价单报价详情</div>
            <div class="cust-content">
                <table>
                    <tbody>
                        <tr><td colspan="3" style="text-align: left">报价编号: {{custInfo.qSn}}</td><td style="text-align: right" colspan="2">创建时间: {{custInfo.createTime}}</td></tr>
                        <tr><td>客户名称</td><td>所属行业</td><td>服务店铺数</td><td>地址</td><td>联系电话</td></tr>
                        <tr><td>{{custInfo.name}}</td><td>{{custInfo.industryName}}</td><td>{{shopInfo.length}}</td><td>{{custInfo.address}}</td><td>{{custInfo.phone}}</td></tr>
                    </tbody>
                </table>
            </div>
            <div class="pdf-title font28">店铺服务配置详情</div>
            
            <div class="shop-content">
                <div class="shop-item" v-for="(item, index) in shopInfo" :key="index">
                    <div class="shop-name">店铺 {{index + 1}}：{{item.shopName}}</div>
                    <table>
                        <tbody>
                            <tr><td>所属平台</td><td>所属类目</td><td>客服类型</td><td>协议子账号数量</td><td>协议子账号日均咨询量</td><td>服务时间范围</td><td>客服工作时长</td></tr>
                            <tr><td>{{item.platFormName || '--'}}</td><td>{{item.categoryName || '--'}}</td><td>{{item.serviceType == 1 ? '普通客服' : item.serviceType == 2 ? '金牌客服' : '临时客服'}}</td><td>{{item.subaccountNum || '0'}}</td><td>{{item.shopConsultationNum || '0'}}</td><td>{{item.startTime}}~{{item.endTime}} <br/>（{{item.serviceDay}}天）</td><td>{{item.startHour}}~{{item.endHour}}（{{item.workHour}}小时）</td></tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><td class="td-title" colspan="2">约定数据标准：【{{item.dataStandard.type == 'base' ? '基础版' : item.dataStandard.type == 'register' ? '专业版' : '旗舰版'}}】</td></tr>
                            <tr><td>首响</td><td>{{item.dataStandard['首响']}}s</td></tr>
                            <tr><td>平响</td><td>{{item.dataStandard['平响']}}s</td></tr>
                            <tr><td>转换率</td><td>{{item.dataStandard['转换率']}}%</td></tr>
                            <tr><td>三分钟回复率</td><td>{{item.dataStandard['三分钟回复率']}}%</td></tr>
                            <tr><td>五分钟回复率</td><td>{{item.dataStandard['五分钟回复率']}}%</td></tr>
                        </tbody>
                    </table>
                    <div v-for="(itemTwo, indexTwo) in item.serviceContent" :key="indexTwo">
                       <table v-if="itemTwo.isChoose == 1">
                            <tbody>
                                <tr><td class="td-title" colspan="4">【{{itemTwo.step == 0 ? '售前' : itemTwo.step == 1 ? '售中' : itemTwo.step == 2 ? '售后' : '附加类'}}】服务内容配置</td></tr>
                                <tr><td>目录</td><td>基础</td><td>登记</td><td>通知</td></tr>
                                <tr v-for="(itemThree, indexThree) in itemTwo.value" :key="indexThree">
                                    <td>{{itemThree.name}}</td><td><i class="el-icon-check"></i></td><td><i v-if="itemThree.isRegist == 1" class="el-icon-check"></i></td><td><i v-if="itemThree.isNotice == 1" class="el-icon-check"></i></td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>   
                </div>
                
              
            </div>
            <div class="price">
                <span>配置总费用</span> 
                <span>￥</span>
                <span>{{(price).toFixed(2)}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import htmlToPdf from "../../utils/htmlToPdf.js"
import { quotedSuccess, calculationPrice } from "../../service/quoted.js"
export default {
    data () {
        return {
            price: 0,
            shopInfo: [],
            custInfo: {}
        }
    },
    mounted() {
        this.quotedSuccess()
        this.calculationPrice(1)
    },
    methods: {
        handleDown (name) { // 导出pdf
            htmlToPdf.downloadPDF(document.querySelector('#pdf'), name)
        },
        async calculationPrice(type) { // 计算价格
            let { data } = await calculationPrice({id: this.$route.query.id, type: type})
            this.price = data.price
        },
        async quotedSuccess() { // 报价完成-详情
            let { data } = await quotedSuccess({id: this.$route.query.id})
            data.map((item) => {
                item.dataStandard = JSON.parse(item.dataStandard)
                item.serviceContent = JSON.parse(item.serviceContent)
            })
            this.shopInfo = data
            this.custInfo = data ? data[0] : {}
        }
    }
}
</script>
<style lang="less" scoped>
.quotation-container {
    background: #fff;
    text-align: center;
    padding: 1px 0;
    .downQuote {
        margin: 50px 16px 0 0;
        width: 128px;
        height: 40px;
        color: #1890ff;
        border-color: #1890ff;
    }
    .downQuoteGrey {
        margin: 50px 105px 0 0;
        width: 80px;
        height: 40px;
    }
    
    #pdf {
        width: 100%;
        margin: auto;
        color: #101010;
        padding: 0 5%;
        box-sizing: border-box;
        .price {
            text-align: right;
            width: 100%;
            color: #666;
            font-size: 18px;
            margin-bottom: 40px;
            span {
                &:nth-of-type(2) {
                    color: #1890ff;
                    margin-left: 16px;
                }
                &:nth-of-type(3) {
                    color: #1890ff;
                    font-size: 36px;
                }
            }
        }
        .td-title {
            background: #eee;
        }
        td {
            border: 1px solid #333;
            padding: 12px;
            width: min-content;
            font-size: 18px;
        }
        .shop-content {
            .shop-name {
                font-size: 32px;
                margin: 0 auto 20px;
            }
            table {
                margin-bottom: 70px;
            }
        }
        .pdf-title {
            font-size: 42px;
            margin: 0 auto 40px;
            padding-top: 80px;
            font-weight: 700;
        }
        .font28 {
            font-size: 28px !important;
        }
    }
}
</style>